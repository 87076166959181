import {
  COMMUNITY_USER_TAG_WITHOUT_USER_FRAGMENT,
} from '@/graphql/_Fragments/CommunityUserTag/WithoutUser';

export const COMMUNITY_USER_WITH_TAGS_FRAGMENT = `
  fragment communityUserWithTagsFragment on CommunityUser {
    uid
   tags: _myTags(myUid: "%authUser%") {
      ...communityUserTagWithoutUserFragment
    }
  }
  ${COMMUNITY_USER_TAG_WITHOUT_USER_FRAGMENT}
`;
